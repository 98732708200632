<template>
    <div class="row pb-3">
        <!-- <div class="col-2 pt-2">
            <label for="file-upload" class="btn btn-success btn-rounded">
                <b-icon icon="file-earmark-arrow-up"></b-icon>
            </label>
            <input id="file-upload" type="file" ref="miarchivo" @change="changeFiles"/>
        </div> -->
        <div class="col-9 col-md-10">
            <b-form-input placeholder="Enter message to chat" v-model="form.text" style="margin-bottom: 0px !important" v-on:keyup.enter="sendMessage"/>
            <small v-if="form.file" class="text-muted" style="font-size:12pxp">{{form.file.name}}</small>
        </div>
        <div class="col-3 col-md-2">
            <ButtonAction :loading="loading" loadingTx="Sending" title="Send" block="true" @click="sendMessage"/>
        </div>
        <!-- <div class="col-12" v-if="$store.state.progressBarState != null">
            <b-progress-bar style="height: 10px !important; border-radius: 10px;" class="mt-4" :value="$store.state.progressBarState" variant="success"></b-progress-bar>
        </div> -->
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['id'],
    data () {
        return {
            loading: false,
            form: {
                to_user_id: null,
                text: null,
                file: null
            },
            name: null
        }
    },
    methods: {
        ...mapActions('chat', ['newMessage']),
        changeFiles () {              
            this.form.file = this.$refs.miarchivo.files[0]
        },
        sendMessage () {
            this.form.to_user_id = this.id
            if(this.form.text == null || this.form.text == '' || this.form.to_user_id == null)
            {
                return
            }
            this.loading = true

            const formData = new FormData()
            formData.append('to_user_id', this.form.to_user_id)
            formData.append('text', this.form.text)
            formData.append('file', this.form.file)


            this.newMessage(formData).then(() => {
                this.form.text = null
                this.form.file = null
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState(['progressBarState'])
    }
}
</script>
<style scoped>
    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
    }
</style>